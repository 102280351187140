import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Deadlifts 2-2-2-2-2 to a 2RM`}</p>
    <p>{`then,`}</p>
    <p>{`50-40-30-20-10 Double Unders`}</p>
    <p>{`25-20-15-10-5 SDHP’s (95/65)`}</p>
    <p>{`For time.`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`7:00 AMRAP of:`}</p>
    <p>{`200ft Shuttle Run (in 50ft increments)`}</p>
    <p>{`10 Burpees`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Open WOD 18.3 will be announced tonight at 8:00pm at
Games.CrossFit.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      